<template>
  <section>
    <div class="card">
      <div class="card-header">
        <div class="utils__title">
          <strong>Uživatelé - Zákazníci - detail <span
            class="utils__link--underlined text-primary">#{{ this.id }}</span></strong>
        </div>
        <action-tools :router-options="routerOptions"></action-tools>
      </div>
      <div class="card-body">
        <div class="table-operations">
          <a-tabs @change="key => onTabChange(key, 'tabActiveKey')" :activeKey="tabActiveKey">
            <a-tab-pane v-for="item in tabList" :key="item.key">
              <span slot="tab">
                <a-icon :type="item.icon"/>
                {{ item.tab }}
              </span>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>

    <div v-if="detail.language">
      <div v-if="tabActiveKey === 'info'">
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="12" :md="12" :sm="12" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Aktivity na účtu</strong>
                </div>
                <a-alert v-if="isUserBanned()" type="warning" showIcon message="Uživatel je zablokován">
                  <div slot="description" v-if="lastBanTo !== null">
                    <div>
                      <span class="font-weight-bold mr-2">Od:</span><span>{{ lastBanTo.banned_from | formatDate }}</span>
                    </div>
                    <div>
                      <span class="font-weight-bold mr-2">Do:</span><span>{{ lastBanTo.banned_to | formatDate }}</span>
                    </div>
                  </div>
                </a-alert>
              </div>
              <div class="card-body">
                <div>
                  <span class="font-weight-bold mr-2">Poslední přihlášení:</span><span>{{ detail.last_login_at | formatDate }}</span>
                </div>
                <div>
                  <span class="font-weight-bold mr-2">Poslední IP adresa:</span><span>{{ detail.last_ip_address.ias_ip_address }}</span>
                </div>
                <div>
                  <span
                    class="font-weight-bold mr-2">Registrace:</span><span>{{ detail.created_at | formatDate }}</span>
                </div>
                <div>
                  <span class="font-weight-bold mr-2">Aktivní:</span>
                  <span v-if="detail.uer_is_active" class="badge badge-success font-size-12">Ano</span>
                  <span v-else class="badge badge-danger font-size-12">Ne</span>
                </div>
                <div v-if="detail.deleted_at">
                  <span
                    class="font-weight-bold mr-2">Odstraněny:</span><span>{{ detail.deleted_at | formatDate }}</span>
                </div>
              </div>
            </div>
          </a-col>
          <a-col :xl="12" :md="12" :sm="12" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Ostatní informace</strong>
                </div>
              </div>
              <div class="card-body">
                <div>
                  <span class="font-weight-bold mr-2">Jazyk:</span><span>{{ detail.language.lge_name }}</span>
                </div>
                <div>
                  <span class="font-weight-bold mr-2">Měna:</span><span>{{detail.language.currency.name}} [{{ detail.language.currency.symbol }}]</span>
                </div>
                <div>
                  <span class="font-weight-bold mr-2">E-mail:</span>
                  <a-button class="p-0" @click="copyEmailsToClipboard(detail.email + ';')" size="small" type="link">{{ detail.email }}</a-button>
                </div>
                <div>
                  <span class="font-weight-bold mr-2">Odebírá novinky:</span>
                  <span v-if="detail.uer_is_newsletter" class="badge badge-success font-size-12">Ano</span>
                  <span v-else class="badge badge-danger font-size-12">Ne</span>
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="12" :md="12" :sm="12" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>IP adresy</strong>
                </div>
              </div>
              <div class="card-body">
                <a-table
                  tableLayout="auto"
                  :rowKey="record => record.ias_id"
                  :columns="ipAddressesColumns"
                  :pagination="{hideOnSinglePage: true}"
                  :loading="loading"
                  :dataSource="detail.ip_addresses"
                  class="utils__scrollTable"
                  :scroll="{ x: '100%' }"
                >
                  <span
                    slot="ias_id"
                    slot-scope="value"
                  >#{{value}}
                  </span>
                  <template slot="customRender" slot-scope="text">
                    {{text}}
                  </template>
                  <span
                    slot="dates"
                    slot-scope="text"
                  >
                    {{ text | formatDate }}
                  </span>
                </a-table>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'discounts'">
        <a-row type="flex" justify="start" :gutter="30">
          <a-col v-for="(item, groupIndex) in detail.user_group_histories" :key="'discount' + item.grp_id" :xl="12" :md="12" :sm="12" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Slevy pro {{ item.is_private ? 'soukromou' : 'veřejnou' }} skupinu <span class="utils__link--underlined text-primary">{{ item.grp_name }}</span></strong>
                </div>
              </div>
              <div class="card-body">
                <a-form :form="groupDiscountsForm">
                  <a-tree
                    class="tree-with-input"
                    :treeData="categoryDiscountTreeData"
                    :showIcon="true"
                  >
                    <div slot="custom" slot-scope="{title}">
                      <a-input-number v-if="categoryDiscount.categories[groupIndex].items.length !== 0" class="w-100" placeholder="Sleva %" :min="item.is_private ? -1 : 0" :max="100" :step="0.01" :precision="2" v-model="categoryDiscount.categories[groupIndex].items.find(x => x.cey_id === Number.parseInt(title.substr(1))).gct_discount" :defaultValue="item.is_private ? -1 : 0" />
                    </div>
                  </a-tree>
                  <div class="form-actions">
                    <a-modal
                      :visible="groupIndex === discountModalVisible"
                      title="Potvrzení formuláře"
                      cancel-text="Zrušit"
                    >
                      <a-form-item label="Zvolte způsob aktualizace slev">
                        <a-radio-group v-model="discountOption">
                          <a-radio :style="radioStyle" :value="2">
                            Přepsat slevu jen u produktů, které mají danou kategorii jako hlavní
                          </a-radio>
                          <a-radio :style="radioStyle" :value="3">
                            Nepřepisovat slevu u produktů
                          </a-radio>
                        </a-radio-group>
                      </a-form-item>
                      <template #footer>
                        <a-button key="back" @click="hideDiscountModal">Zrušit</a-button>
                        <a-button key="submit" type="primary" @click="onSubmitUpdateGroupDiscounts($event, item.grp_id, groupIndex)" :disabled="loading || hasErrors(groupDiscountsForm.getFieldsError())">Upravit</a-button>
                      </template>
                    </a-modal>
                    <a-button type="primary" @click="showDiscountModal(groupIndex)" :disabled="loading || hasErrors(groupDiscountsForm.getFieldsError())">Upravit</a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'addresses'">
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="8" :md="8" :sm="12" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Adresa v profilu</strong>
                </div>
              </div>
              <div class="card-body">
                <address v-if="detail.account_address">
                  <div class="mb-2">
                    <span class="font-weight-bold mr-2">Vytvořeno:</span>
                    {{ detail.account_address.created_at | formatDate }}<br>
                    <span class="font-weight-bold mr-2">Poslední úprava:</span>
                    {{ detail.account_address.updated_at | formatDate }}<br>
                  </div>
                  {{ detail.account_address.name }}<br>
                  {{ detail.account_address.street }}<br>
                  {{ detail.account_address.zip + ' ' + detail.account_address.city }}<br>
                  {{ countryName(detail.account_address.country_id ) }}
                </address>
              </div>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="12" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Kontaktní údaje</strong>
                </div>
              </div>
              <div class="card-body">
                <template v-if="detail.contact">
                  <div v-if="detail.contact.telephone_number">
                    <span class="font-weight-bold mr-2">Telefon:</span>{{ detail.contact.telephone_number }}
                  </div>
                  <div v-if="detail.contact.email">
                    <span class="font-weight-bold mr-2">E-mail:</span>{{ detail.contact.email }}
                  </div>
                </template>
                <a-empty v-else></a-empty>
              </div>
            </div>
          </a-col>
          <a-col :xl="8" :md="8" :sm="12" :xs="24">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Firemní údaje</strong>
                </div>
              </div>
              <div class="card-body">
                <template v-if="detail.company">
                  {{ detail.company.name }}<br>
                  <div v-if="detail.company.tin">
                    <span class="font-weight-bold mr-2">IČ:</span>{{ detail.company.tin }}
                  </div>
                  <div v-if="detail.company.vatin">
                    <span class="font-weight-bold mr-2">DIČ:</span>{{ detail.company.vatin }}
                  </div>
                </template>
                <a-empty v-else></a-empty>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'reviews'">
        <div class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Recenze</strong>
            </div>
          </div>
          <div class="card-body">
            <a-list itemLayout="vertical" size="large"
                    :pagination="detail.reviews.length > pagination.reviews.pageSize ? pagination.reviews : false"
                    :dataSource="detail.reviews ? detail.reviews : []">
              <a-list-item v-if="item.deleted === undefined" slot="renderItem" slot-scope="item" :key="item.rvw_id">
                <div slot="extra">
                  <a-dropdown>
                    <a-button icon="down">Akce</a-button>
                    <a-menu slot="overlay">
                      <a-menu-item v-if="!isUserBanned()" @click="clickShowModal(item)">
                        <a-icon type="stop" class="mr-2"/>
                        Zabanovat uživatele
                      </a-menu-item>
                      <a-menu-item>
                        <a-popconfirm @confirm="deleteReview(item)">
                          <template slot="title">
                            <p>Opravdu si přejete odstranit tuto položku?</p>
                          </template>
                          <a-icon type="delete" class="mr-2"/>
                          Odstranit
                        </a-popconfirm>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
                <a-row type="flex" justify="start" :gutter="30">
                  <a-col :lg="6" :xs="24">
                    <div class="list-avatar-user text-center">
                      <div>
                        <cui-avatar
                          src="/resources/images/avatar.jpg"
                          :size="50"
                          :link="''"
                          :bordered="false"
                        />
                      </div>
                      <small class="d-block text-muted mt-2">{{item.created_at | formatDate}}</small>
                      <div>
                        <a-rate :value="parseFloat(item.rvw_rating)" :allowHalf="true" :disabled="true"/>
                      </div>
                    </div>
                  </a-col>
                  <a-col :lg="18" :xs="24" :class="item.created_at !== item.updated_at ? 'pb-5' : ''">
                    <small class="d-block text-muted position-absolute bottom-0"
                           v-if="item.created_at !== item.updated_at">Aktualizováno: {{item.updated_at |
                      formatDate}}</small>
                    <router-link class="utils__link--underlined" :to="'/ecommerce/product/detail/' + item.put_id">{{
                      item.product.history_languages_without_pivot[0].phe_name }}
                    </router-link>
                    <div v-if="item.rvw_title !== null" class="mt-2">
                      <div class="ant-list-item-meta-title">{{ item.rvw_title }}</div>
                    </div>
                    <div v-if="item.rvw_message !== null" class="mt-4">
                      {{item.rvw_message}}
                    </div>
                    <a-row v-if="item.rvw_pros !== null || item.rvw_cons !== null" class="mt-3">
                      <a-col :span="12" v-if="item.rvw_pros !== null">
                        <ul class="list-unstyled">
                          <li v-for="(pro, index) in JSON.parse(item.rvw_pros)" :key="'pro'+index">
                            <a-icon type="plus-circle" theme="twoTone" twoToneColor="#52c41a"></a-icon>
                            {{ pro.item }}
                          </li>
                        </ul>
                      </a-col>
                      <a-col :span="12" v-if="item.rvw_cons !== null">
                        <ul class="list-unstyled">
                          <li v-for="(con, index) in JSON.parse(item.rvw_cons)" :key="'con'+index">
                            <a-icon type="minus-circle" theme="twoTone" twoToneColor="#eb2f96"></a-icon>
                            {{ con.item }}
                          </li>
                        </ul>
                      </a-col>
                    </a-row>
                  </a-col>
                </a-row>
              </a-list-item>
            </a-list>
          </div>
        </div>
      </div>
      <div v-if="tabActiveKey === 'comments'">
        <div class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Komentáře</strong>
            </div>
          </div>
          <div class="card-body">
            <list-tree
              :tree-data="detail.comments"
              :pagination="pagination.comments"
              :loading="loading"
              :quoteParent="true"
              :active-user-id="detail.uer_id"
              :is-user-banned="isUserBanned"
              :delete-comment="deleteComment"
              :click-show-modal="clickShowModal"
              :reply-to-comment="replyToComment"
              :update-comment="updateComment"
              :load-children-replies="loadChildrenReplies"
            ></list-tree>
          </div>
        </div>
      </div>
      <div v-if="tabActiveKey === 'orders'">
        <div class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Objednávky</strong>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3 text-right">
              <a-input-search
                placeholder="Hledat..."
                style="width: 200px"
                @search="handleOrderSearch"
              />
            </div>
            <a-table
              tableLayout="auto"
              :rowKey="record => record.odr_id"
              :columns="orderColumns"
              :loading="loading"
              :dataSource="detail.orders"
              class="utils__scrollTable"
              :scroll="{ x: '100%' }"
            >
              <template slot="checkFilled" slot-scope="text">
                <div v-if="text">{{ text }}</div>
                <a-alert v-else message="není nastaveno" type="info" showIcon/>
              </template>
              <router-link
                slot="odr_id"
                slot-scope="value"
                class="utils__link--underlined"
                :to="'/ecommerce/order/detail/' + value"
              >#<span v-if="searchOrderText">
            <template
              v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchOrderText})|(?=${searchOrderText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchOrderText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
                <template v-else>{{value}}</template>
              </router-link>
              <span slot="status" slot-scope="text">
            <a-tag :color="statuses.find(x => x.status === text).color">
              <span v-if="searchOrderText">
                <template
                  v-for="(fragment, i) in statuses.find(x => x.status === text).text.toString().split(new RegExp(`(?<=${searchOrderText})|(?=${searchOrderText})`, 'i'))">
                  <mark
                    v-if="fragment.toLowerCase() === searchOrderText.toLowerCase()"
                    :key="i"
                    class="highlight"
                  >{{fragment}}</mark>
                  <template v-else>{{fragment}}</template>
                </template>
              </span>
              <template v-else>{{ statuses.find(x => x.status === text).text }}</template>
            </a-tag>
          </span>
              <span slot="note" slot-scope="text">
          <a-button v-if="text !== null" @click="showNote(text)">Zobrazit</a-button>
        </span>
              <template slot="customRender" slot-scope="text">
          <span v-if="searchOrderText">
            <template
              v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchOrderText})|(?=${searchOrderText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchOrderText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
                <template v-else>{{text}}</template>
              </template>
              <template slot="price" slot-scope="text">
          <span v-if="searchOrderText">
            <template
              v-for="(fragment, i) in text === null ? '0,00' : text.toString().split(new RegExp(`(?<=${searchOrderText})|(?=${searchOrderText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchOrderText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template> Kč
          </span>
                <template v-else>{{ text === null ? 0 : text | currency }}</template>
              </template>
              <span
                slot="active"
                slot-scope="value"
                :class="[value === undefined ? '' : value ? 'font-size-12 badge badge-success' : 'font-size-12 badge badge-danger']"
              >
          <span v-if="searchOrderText">
            <template
              v-for="(fragment, i) in (value ? 'ano' : 'ne').toString().split(new RegExp(`(?<=${searchOrderText})|(?=${searchOrderText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchOrderText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{value ? 'ano' : 'ne'}}</template>
        </span>
              <span
                slot="dates"
                slot-scope="text"
              >
          <span v-if="searchOrderText">
            <template
              v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchOrderText})|(?=${searchOrderText})`, 'i'))">
              <mark
                v-if="fragment.toLowerCase() === searchOrderText.toLowerCase()"
                :key="i"
                class="highlight"
              >{{fragment}}</mark>
              <template v-else>{{fragment}}</template>
            </template>
          </span>
          <template v-else>{{ text | formatDate }}</template>
        </span>
            </a-table>
          </div>
        </div>
      </div>
      <div v-if="tabActiveKey === 'cart'">
        <div class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Obsah košíku</strong>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3 text-right">
              <a-input-search
                placeholder="Hledat..."
                style="width: 200px"
                @search="handleCartSearch"
              />
            </div>
            <a-table
              tableLayout="auto"
              :rowKey="record => record.cim_id"
              :columns="cartColumns"
              :loading="loading"
              :dataSource="detail.cart && detail.cart.cart_items ? detail.cart.cart_items : []"
              class="utils__scrollTable"
              :scroll="{ x: '100%' }"
            >
              <router-link
                slot="put_id"
                slot-scope="value"
                class="utils__link--underlined"
                :to="'/ecommerce/product/detail/' + value"
              >#
                <span v-if="searchCartText">
                  <template
                    v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchCartText})|(?=${searchCartText})`, 'i'))">
                    <mark
                      v-if="fragment.toLowerCase() === searchCartText.toLowerCase()"
                      :key="i"
                      class="highlight"
                    >{{fragment}}</mark>
                    <template v-else>{{fragment}}</template>
                  </template>
                </span>
                <template v-else>{{value}}</template>
              </router-link>
              <template slot="customRender" slot-scope="text">
                <span v-if="searchCartText">
                  <template
                    v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchCartText})|(?=${searchCartText})`, 'i'))">
                    <mark
                      v-if="fragment.toLowerCase() === searchCartText.toLowerCase()"
                      :key="i"
                      class="highlight"
                    >{{fragment}}</mark>
                    <template v-else>{{fragment}}</template>
                  </template>
                </span>
                <template v-else>{{text}}</template>
              </template>
              <span slot="productName" slot-scope="record">
                {{ record.product.history_languages_without_pivot[0].phe_name }}
                <span v-if="record.pon_id"> - {{ record.product_option.languages[0].pivot.poe_name }}</span>
              </span>
              <span
                slot="dates"
                slot-scope="text"
              >
                <span v-if="searchCartText">
                  <template
                    v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchCartText})|(?=${searchCartText})`, 'i'))">
                    <mark
                      v-if="fragment.toLowerCase() === searchCartText.toLowerCase()"
                      :key="i"
                      class="highlight"
                    >{{fragment}}</mark>
                    <template v-else>{{fragment}}</template>
                  </template>
                </span>
                <template v-else>{{ text | formatDate }}</template>
              </span>
            </a-table>
          </div>
        </div>
      </div>
      <div v-if="tabActiveKey === 'wish-list'">
        <div class="card">
          <div class="card-header">
            <div class="utils__title">
              <strong>Oblíbené produkty</strong>
            </div>
          </div>
          <div class="card-body">
            <div class="mb-3 text-right">
              <a-input-search
                placeholder="Hledat..."
                style="width: 200px"
                @search="handleWishListSearch"
              />
            </div>
            <a-table
              tableLayout="auto"
              :rowKey="record => record.wlm_id"
              :columns="wishListColumns"
              :loading="loading"
              :dataSource="detail.wish_list && detail.wish_list.wish_list_items ? detail.wish_list.wish_list_items : []"
              class="utils__scrollTable"
              :scroll="{ x: '100%' }"
            >
              <router-link
                slot="put_id"
                slot-scope="value"
                class="utils__link--underlined"
                :to="'/ecommerce/product/detail/' + value"
              >#
                <span v-if="searchWishListText">
                  <template
                    v-for="(fragment, i) in value.toString().split(new RegExp(`(?<=${searchWishListText})|(?=${searchWishListText})`, 'i'))">
                    <mark
                      v-if="fragment.toLowerCase() === searchWishListText.toLowerCase()"
                      :key="i"
                      class="highlight"
                    >{{fragment}}</mark>
                    <template v-else>{{fragment}}</template>
                  </template>
                </span>
                <template v-else>{{value}}</template>
              </router-link>
              <template slot="customRender" slot-scope="text">
                <span v-if="searchWishListText">
                  <template
                    v-for="(fragment, i) in text.toString().split(new RegExp(`(?<=${searchWishListText})|(?=${searchWishListText})`, 'i'))">
                    <mark
                      v-if="fragment.toLowerCase() === searchWishListText.toLowerCase()"
                      :key="i"
                      class="highlight"
                    >{{fragment}}</mark>
                    <template v-else>{{fragment}}</template>
                  </template>
                </span>
                <template v-else>{{text}}</template>
              </template>
              <span slot="productName" slot-scope="record">
                {{ record.product.history_languages_without_pivot[0].phe_name }}
                <span v-if="record.pon_id"> - {{ record.product_option.languages[0].pivot.poe_name }}</span>
              </span>
              <span
                slot="dates"
                slot-scope="text"
              >
                <span v-if="searchWishListText">
                  <template
                    v-for="(fragment, i) in toLocaleDate(text).toString().split(new RegExp(`(?<=${searchWishListText})|(?=${searchWishListText})`, 'i'))">
                    <mark
                      v-if="fragment.toLowerCase() === searchWishListText.toLowerCase()"
                      :key="i"
                      class="highlight"
                    >{{fragment}}</mark>
                    <template v-else>{{fragment}}</template>
                  </template>
                </span>
                <template v-else>{{ text | formatDate }}</template>
              </span>
            </a-table>
          </div>
        </div>
      </div>
      <div v-if="tabActiveKey === 'survey'">
        <div class="card" v-if="detail.user_answers === null || detail.user_answers.length === 0">
          <div class="card-header">
            <div class="utils__title">
              <strong>Odpovědi na dotazníky</strong>
            </div>
          </div>
          <div class="card-body">
            <a-empty></a-empty>
          </div>
        </div>
        <a-row v-else type="flex" justify="start" :gutter="30">
          <a-col :xl="8" :md="8" :sm="12" :xs="24" :key="survey.uar_id" v-for="survey in detail.user_answers">
            <div class="card">
              <div class="card-header">
                <div class="utils__title">
                  <strong>Otázka: {{ survey.survey_answer.survey.languages[0].pivot.sle_question }}?</strong>
                </div>
                <small class="text-muted d-block">Odpovězeno: {{ survey.created_at | formatDate }}</small>
              </div>
              <div class="card-body">
                <p>Možnosti:</p>
                <ol>
                  <li :class="answer.sar_id === survey.sar_id ? 'font-weight-bold' : ''" :key="answer.sar_id"
                      v-for="answer in survey.survey_answer.survey.answers">{{ answer.languages[0].pivot.sae_answer }}
                  </li>
                </ol>
                <div>Odpověď: <strong>{{ survey.survey_answer.survey.answers.find(x => x.sar_id ===
                  survey.sar_id).languages[0].pivot.sae_answer }}</strong>.
                </div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
      <div v-if="tabActiveKey === 'notes'">
        <div class="card" v-if="detail.user_notes === null || detail.user_notes.length === 0">
          <div class="card-header">
            <div class="utils__title">
              <strong>Poznámky k zákazníkovi</strong>
            </div>
          </div>
          <div class="card-body">
            <a-empty></a-empty>
          </div>
        </div>
        <a-row type="flex" justify="start" :gutter="30">
          <a-col :xl="8" :md="12" :xs="24" v-for="item in detail.user_notes" :key="'note'+item.id">
            <div class="card card--withShadow">
              <div class="card-header">
                <div class="utils__titleDescription">Posledně upraveno: {{ toLocaleDate(item.updated_at) }}</div>
                <div class="card--more">
                  <a-dropdown :placement="'bottomRight'">
                    <a-button type="circle">
                      <a-icon type="ellipsis" :rotate="90"></a-icon>
                    </a-button>
                    <a-menu slot="overlay" @click="clickActionNote($event, item)">
                      <a-menu-item :key="0">
                        <a-icon type="edit" class="mr-2"></a-icon>Upravit
                      </a-menu-item>
                      <a-menu-item :key="1">
                        <a-popconfirm @confirm="deleteNote(item)" okText="Potvrdit" cancelText="Zrušit">
                          <template slot="title">
                            <p>Opravdu si přejete odstranit položku: #{{ item.id }} ?</p>
                          </template>
                          <a-icon type="delete" class="mr-2"></a-icon>Odstranit
                        </a-popconfirm>
                      </a-menu-item>
                    </a-menu>
                  </a-dropdown>
                </div>
              </div>
              <div class="card-body">
                <div v-if="editable.user_note !== item.id">
                  <div class="utils__title">
                    <strong>{{ item.title }}</strong>
                  </div>
                  {{ item.message }}
                </div>
                <a-form :form="form" @submit="onSubmitUpdateNote($event, item)" v-else>
                  <a-form-item
                    label="Nadpis"
                    :validate-status="error('title') ? 'error' : ''"
                    :help="error('title') || ''"
                  >
                    <a-input placeholder="Nadpis" v-decorator="['title', { initialValue: item.title, rules: [{max: 50, message: 'Nadpis nemůže být delší než 50 znaků!'}]}]"/>
                  </a-form-item>

                  <a-form-item
                    label="Obsah"
                    :validate-status="error('message') ? 'error' : ''"
                    :help="error('message') || ''"
                  >
                    <a-textarea :autoSize="{ minRows: 4, maxRows: 10 }" placeholder="Obsah" v-decorator="['message', {initialValue: item.message, rules: [{required: true, message: 'Obsah musí být vyplněn!'}]}]"/>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="editable.user_note = null" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Upravit</a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
          <a-col :xl="6" :md="8" :sm="12" :xs="24">
            <a-button
              type="dashed"
              block
              size="large"
              @click="onClickAddNote"
              v-if="!isAddNote"
            >
              <a-icon type="plus" /> Přidat poznámku
            </a-button>
            <div class="card" v-else>
              <div class="card-body">
                <a-form :form="form" @submit="onSubmitAddNote">
                  <a-form-item
                    label="Nadpis"
                    :validate-status="error('one_title') ? 'error' : ''"
                    :help="error('one_title') || ''"
                  >
                    <a-input placeholder="Nadpis" v-decorator="['title', {rules: [{max: 50, message: 'Nadpis nemůže být delší než 50 znaků!'}]}]"/>
                  </a-form-item>

                  <a-form-item
                    label="Obsah"
                    :validate-status="error('one_content') ? 'error' : ''"
                    :help="error('one_content') || ''"
                  >
                    <a-textarea :autoSize="{ minRows: 4, maxRows: 10 }" placeholder="Obsah" v-decorator="['message', {rules: [{required: true, message: 'Obsah musí být vyplněn!'}]}]"/>
                  </a-form-item>

                  <div class="form-actions">
                    <a-button class="mr-2" @click="isAddNote = !isAddNote" type="default">Zrušit</a-button>
                    <a-button html-type="submit" type="primary" :disabled="loading || hasErrors(form.getFieldsError())">Přidat</a-button>
                  </div>
                </a-form>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
    <a-modal title="Zabanovat na X dní" v-model="visible" @ok="banUser(visibleId, daysBanTo, visibleUser)">
      <template slot="footer">
        <a-button key="back" @click="() => this.visible = false">Storno</a-button>
        <a-button key="submit" type="primary" :loading="loading" @click="banUser(visibleId, daysBanTo, visibleUser)">
          Ok
        </a-button>
      </template>
      <a-input-number placeholder="Zabanovat na X dní" :min="1" :defaultValue="1" v-model="daysBanTo"></a-input-number>
    </a-modal>
  </section>
</template>

<script>
import ActionTools from '@/components/MyComponents/ActionTools'
import ListTree from '@/components/MyComponents/Comments/ListTree'
import CuiAvatar from '@/components/CleanUIComponents/Avatar'
import copy from '@/services/copy'
import { hasErrors } from '../../../../services/forms'

export default {
  mixins: [copy],
  beforeCreate() {
    this.form = this.$form.createForm(this)
    this.groupDiscountsForm = this.$form.createForm(this)
  },
  components: { ActionTools, ListTree, CuiAvatar },
  data: function () {
    return {
      hasErrors,
      isAddNote: false,
      visible: null,
      visibleId: null,
      discountOption: 1,
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
      discountModalVisible: false,
      categoryDiscountTreeData: [],
      categoryDiscount: {
        categories: [{
          loading: false,
          items: [],
        }, {
          loading: false,
          items: [],
        }],
      },
      editable: {
        user_note: null,
      },
      visibleUser: null,
      daysBanTo: 1,
      searchOrderText: '',
      searchWishListText: '',
      searchCartText: '',
      id: this.$route.params.id,
      loading: false,
      tabActiveKey: 'info',
      routerOptions: [
        {
          icon: 'bars',
          theme: 'outlined',
          to: '/user/registered',
          title: 'Seznam',
        },
      ],
      orderColumns: [
        {
          title: 'ID',
          dataIndex: 'odr_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.odr_id - b.odr_id,
          scopedSlots: {
            customRender: 'odr_id',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Stav',
          dataIndex: 'current_history.ohy_status',
          sorter: (a, b) => a.current_history.ohy_status.localeCompare(b.current_history.ohy_status),
          scopedSlots: {
            customRender: 'status',
          },
        },
        {
          title: 'Poznámka',
          dataIndex: 'odr_note',
          sorter: (a, b) => (a.odr_note === null) - (b.odr_note === null),
          scopedSlots: {
            customRender: 'note',
          },
        },
        {
          title: 'Stornováno uživatelem',
          dataIndex: 'is_canceled_by_user',
          sorter: (a, b) => (a.is_canceled_by_user === null) - (b.is_canceled_by_user === null),
          scopedSlots: {
            customRender: 'active',
          },
        },
        {
          title: 'Cena',
          dataIndex: 'order_total_vat_rounded',
          sorter: (a, b) => {
            return a.order_total_vat_rounded - b.order_total_vat_rounded
          },
          scopedSlots: {
            customRender: 'price',
          },
        },
      ],
      ipAddressesColumns: [
        {
          title: 'ID',
          dataIndex: 'ias_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.ias_id - b.ias_id,
          scopedSlots: {
            customRender: 'ias_id',
          },
        },
        {
          title: 'IP adresa',
          dataIndex: 'ias_ip_address',
          sorter: (a, b) => a.ias_ip_address.localeCompare(b.ias_ip_address),
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Zákaz od',
          dataIndex: 'banned_from',
          sorter: (a, b) => new Date(a.banned_from) - new Date(b.banned_from),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Zákaz do',
          dataIndex: 'banned_to',
          sorter: (a, b) => new Date(a.banned_to) - new Date(b.banned_to),
          scopedSlots: {
            customRender: 'dates',
          },
        },
      ],
      wishListColumns: [
        {
          title: 'ID',
          dataIndex: 'put_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.put_id - b.put_id,
          scopedSlots: {
            customRender: 'put_id',
          },
        },
        {
          title: 'Produkt',
          dataIndex: '',
          sorter: (a, b) => a.product.history_languages_without_pivot[0].phe_name.localeCompare(b.product.history_languages_without_pivot[0].phe_name),
          scopedSlots: {
            customRender: 'productName',
          },
        },
        {
          title: 'Množství',
          dataIndex: 'quantity',
          sorter: (a, b) => a.quantity - b.quantity,
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Poslední úprava',
          dataIndex: 'updated_at',
          sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
      ],
      cartColumns: [
        {
          title: 'ID',
          dataIndex: 'put_id',
          defaultSortOrder: 'ascend',
          sorter: (a, b) => a.put_id - b.put_id,
          scopedSlots: {
            customRender: 'put_id',
          },
        },
        {
          title: 'Produkt',
          dataIndex: '',
          sorter: (a, b) => a.product.history_languages_without_pivot[0].phe_name.localeCompare(b.product.history_languages_without_pivot[0].phe_name),
          scopedSlots: {
            customRender: 'productName',
          },
        },
        {
          title: 'Množství',
          dataIndex: 'cim_quantity',
          sorter: (a, b) => a.cim_quantity - b.cim_quantity,
          scopedSlots: {
            customRender: 'customRender',
          },
        },
        {
          title: 'Vytvořeno',
          dataIndex: 'created_at',
          sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
        {
          title: 'Poslední úprava',
          dataIndex: 'updated_at',
          sorter: (a, b) => new Date(a.updated_at) - new Date(b.updated_at),
          scopedSlots: {
            customRender: 'dates',
          },
        },
      ],
      tabList: [
        {
          key: 'info',
          icon: 'info-circle',
          tab: 'Informace',
        },
        {
          key: 'discounts',
          icon: 'percentage',
          tab: 'Slevy',
        },
        {
          key: 'addresses',
          icon: 'environment',
          tab: 'Adresa',
        },
        {
          key: 'orders',
          icon: 'shopping-cart',
          tab: 'Objednávky',
        },
        {
          key: 'wish-list',
          icon: 'heart',
          tab: 'Oblíbené produkty',
        },
        {
          key: 'cart',
          icon: 'shopping',
          tab: 'Obsah košíku',
        },
        {
          key: 'survey',
          icon: 'bar-chart',
          tab: 'Odpovědi na dotazníky',
        },
        {
          key: 'reviews',
          icon: 'star',
          tab: 'Recenze',
        },
        {
          key: 'comments',
          icon: 'message',
          tab: 'Komentáře',
        },
        {
          key: 'notes',
          icon: 'book',
          tab: 'Poznámky',
        },
      ],
      pagination: {
        comments: {
          pageSize: 10,
        },
        reviews: {
          pageSize: 10,
        },
      },
    }
  },
  computed: {
    lastBanTo: function () {
      let found = null
      this.detail.ip_addresses.some((value) => {
        if (this.$moment().diff(value.banned_to) <= 0) {
          found = value
          return true
        }
      })
      return found
    },
    statuses: function () {
      return this.$store.getters['order/getStatuses']
    },
    countries: function () {
      return this.$store.getters['country/currentLanguage']('')
    },
    user: function () {
      return this.$store.getters['auth/getItem']
    },
    detail: function () {
      return this.$store.getters['userRegistered/getDetail']
    },
    categories: function() {
      return this.$store.getters['category/currentLanguage']
    },
    language: function () {
      return this.$store.getters['language/active']
    },
  },
  methods: {
    showDiscountModal(groupIndex) {
      this.discountModalVisible = groupIndex
    },
    hideDiscountModal() {
      this.discountModalVisible = -1
    },
    error(fieldName) {
      const { getFieldError, isFieldTouched } = this.form
      return isFieldTouched(fieldName) && getFieldError(fieldName)
    },
    onClickAddNote() {
      this.isAddNote = true
    },
    onSubmitUpdateNote(e, item) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('userNote/put', { userId: this.id, userNotesId: item.id, item: values })
            .then((response) => {
              this.$store.commit('userRegistered/UPDATE_USER_NOTE', { userNotesId: item.id, item: response })
              this.editable.user_note = null
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    onSubmitUpdateGroupDiscounts(e, groupId, index) {
      e.preventDefault()
      this.groupDiscountsForm.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('userRegistered/putGroupDiscounts', { userId: this.id, groupId: groupId, data: { option: this.discountOption, discounts: this.categoryDiscount.categories[index].items } })
            .then(() => {})
            .catch(() => {})
            .finally(() => {
              this.hideDiscountModal()
              this.loading = false
            })
        }
      })
    },
    onSubmitAddNote(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          this.loading = true
          this.$store.dispatch('userNote/post', { userId: this.id, item: { title: this.form.getFieldValue('title'), message: this.form.getFieldValue('message') } })
            .then((response) => {
              this.$store.commit('userRegistered/ADD_USER_NOTE', response)
            })
            .catch(() => {})
            .finally(() => {
              this.loading = false
              this.isAddNote = false
            })
        }
      })
    },
    clickActionNote(e, item) {
      if (e.key === 0) {
        this.editable.user_note = item.id
      }
    },
    deleteNote(item) {
      this.loading = true
      this.$store.dispatch('userNote/delete', { userId: this.id, userNotesId: item.id })
        .then(() => {
          this.$store.commit('userRegistered/DELETE_USER_NOTE', item.id)
          this.editable.user_note = null
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    deleteComment(comment, parent) {
      comment.parent = parent
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/delete', [comment.cmt_id])
          .then(() => {
            this.$store.commit('product/DELETE_COMMENT', comment)
            resolve()
          })
          .catch(error => {
            reject(error.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    updateComment(comment, message) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/put', { id: comment.cmt_id, item: { cmt_message: message } })
          .then(() => {
            this.$store.commit('product/UPDATE_COMMENT_MESSAGE', { item: comment, message: message })
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    replyToComment(comment, message) {
      this.loading = true
      const newComment = {
        cmt_message: message,
        cmt_cmt_id: comment.cmt_id,
        put_id: comment.put_id,
        blg_id: comment.blg_id,
        lge_id: comment.lge_id,
        root_id: comment.root_id === null ? comment.cmt_id : comment.root_id,
        uer_id: this.user.uer_id,
      }
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/post', newComment)
          .then((response) => {
            comment.children_count += 1
            const now = this.$moment()
            newComment.created_at = now
            newComment.updated_at = now
            newComment.user = this.user
            newComment.cmt_id = response.data.cmt_id
            if (comment.children) {
              comment.children.unshift(newComment)
            } else {
              comment.children = [newComment]
            }
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    banUser(userRegistered, daysBanTo, user) {
      this.loading = true
      this.$store.dispatch('userRegistered/delete', { days: daysBanTo, items: [userRegistered] })
        .then(() => {
          this.$store.commit('userRegistered/BAN_DETAIL')
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
          this.visible = false
        })
    },
    clickShowModal(user) {
      this.visible = true
      this.visibleId = user.uer_id
      this.visibleUser = user
    },
    deleteReview(review) {
      this.loading = true
      this.$store.dispatch('review/delete', [review.rvw_id])
        .then(() => {
          this.$store.commit('product/DELETE_REVIEW', review)
        })
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    showNote(text) {
      const h = this.$createElement
      this.$modal.info({
        title: 'Poznámka k zákazníkovi',
        content: h('div', {}, [
          h('p', text),
        ]),
        closable: true,
        onOk() {
        },
      })
    },
    handleOrderSearch(searchText) {
      this.searchOrderText = searchText
    },
    handleCartSearch(searchText) {
      this.searchCartText = searchText
    },
    handleWishListSearch(searchText) {
      this.searchWishListText = searchText
    },
    onTabChange(key, type) {
      this[type] = key
    },
    initDetail() {
      this.$store.dispatch('userRegistered/getOne', this.id)
        .then(() => {
          this.categoryDiscount.categories.forEach(x => {
            x.items = []
          })
          this.setDefaultCategories(this.categories)
        })
        .catch(() => {})
    },
    loadCountries() {
      this.loading = true
      this.$store.dispatch('country/getEnabled')
        .catch(() => {})
        .finally(() => {
          this.loading = false
        })
    },
    toLocaleDate: function (text) {
      return this.$options.filters.formatDate(text)
    },
    countryName(id) {
      let name = ''
      this.countries.some((value) => {
        if (value.id === id) {
          name = value.current_language.name
          return true
        }
      })
      return name
    },
    isUserBanned(node) {
      const banned = this.detail.banned_to && this.detail.uer_type !== 'AMR'
      let found = false
      this.detail.ip_addresses.some((value) => {
        if (this.$moment().diff(value.banned_to) <= 0) {
          found = true
          return true
        }
      })
      return banned || found
    },
    loadChildrenReplies(comment) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$store.dispatch('comment/getByParent', comment.cmt_id)
          .then(() => {
            this.$store.commit('product/SET_LOADED_CHILDREN_REPLIES', { item: comment })
            resolve()
          })
          .catch(error => {
            reject(error.errorBag.message)
          })
          .finally(() => {
            this.loading = false
          })
      })
    },
    toTreeData(data) {
      return data.map(x => {
        return {
          key: x.cey_id,
          title: '#' + x.cey_id + ' | ' + x.languages[0].pivot.cle_name,
          children: this.toTreeData(x.all_children),
          selectable: false,
          scopedSlots: { icon: 'custom', key: x.cey_id },
        }
      })
    },
    setDefaultCategories(data) {
      data.forEach(value => {
        this.detail.user_group_histories.forEach((group, groupKey) => {
          let discount = group.is_private ? -1 : 0
          if (group.discounts && group.discounts.length !== 0) {
            const findItem = group.discounts.find(x => x.cey_id === value.cey_id)
            if (findItem && findItem.gct_discount) {
              discount = findItem.gct_discount
            } else if (group.is_private) {
              discount = -1
            } else {
              discount = 0
            }
          }
          this.categoryDiscount.categories[groupKey].items.push({
            cey_id: value.cey_id,
            gct_discount: discount,
          })
        })
        if (value.all_children.length > 0) {
          this.setDefaultCategories(value.all_children)
        }
      })
    },
    initData() {
      this.categoryDiscount.categories.forEach((value, key) => {
        this.categoryDiscount.categories[key].loading = true
      })
      Promise.all([
        this.$store.dispatch('category/getList'),
      ])
        .then(() => {
          this.initDetail()
        })
        .catch(() => {})
        .finally(() => {
          this.categoryDiscount.categories.forEach((value, key) => {
            this.categoryDiscount.categories[key].loading = false
          })
        })
    },
    refreshComponent(id) {
      this.id = id
      this.categoryDiscount = {
        categories: [{
          loading: false,
          items: [],
        }, {
          loading: false,
          items: [],
        }],
      }
    },
  },
  created() {
    if (this.language !== null) {
      this.initData()
    }
  },
  watch: {
    language() {
      if (this.language !== null) {
        this.initData()
      }
    },
    categories(newValue, oldValue) {
      if (newValue !== null && oldValue !== newValue && newValue.length > 0) {
        this.categoryDiscountTreeData = this.toTreeData(this.categories)
        if (Object.keys(this.detail).length > 0) {
          this.categoryDiscount.categories.forEach((x, key) => {
            this.categoryDiscount.categories[key].items = []
          })
          this.setDefaultCategories(this.categories)
          this.groupDiscountsForm.validateFields()
        }
      }
    },
    '$route.params.id'(newId, oldId) {
      this.refreshComponent(newId)
      if (this.language !== null) {
        this.initDetail()
      }
    },
    tabActiveKey(newValue, oldValue) {
      if (newValue === 'addresses' && this.countries.length === 0) {
        this.loadCountries()
      }
    },
  },
}
</script>
